import http from '@/libs/http';

export default {
  track(params) {
    switch (params.type) {
      case 'TRAIN':
        return http.get('/website/track/train_track', { trainName: params.trainName });
      case 'CONTAINER':
        return http.get('/website/track/container_track', { containerId: params.id, containerNo: params.containerNo });
    }
  }
};
