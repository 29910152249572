import http from '@/libs/http';

export default {
  page(params = {}) {
    return http.get('/website/news/page', { ...params }, { silent: true });
  },
  detail(id) {
    return http.get('/website/news/detail', { id });
  }
};
