import http from '@/libs/http';

export default {
  page(filters) {
    return http.get('/website/train/page', { ...filters });
  },
  loadCalendar(filter) {
    return http.get('/website/train/calendar', { ...filter });
  },
  calendarButtonType(totalCabin, usableCabin, excessCabin) {
    if (usableCabin < 1) {
      return 'info';
    } else if (usableCabin < 10) {
      return 'warning';
    } else if (usableCabin <= totalCabin + excessCabin) {
      return 'success';
    }
  }
};
